import React, { useState, useEffect, useRef } from "react";
import Button from "./Button";
import Icon from "./Icon";

const TextField = (props) => {
  const [inputValue, setInputValue] = useState(props.value || "");
  const inputRef = useRef();

  useEffect(() => {
    if (inputValue.toString() !== props.value.toString()) {
      setInputValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (inputValue.toString() !== props.value.toString()) {
      console.log("newLabel inout", inputValue);

      props.onChange && props.onChange(inputValue);
    }
  }, [inputValue]);

  const buildClasses = () => {
    const classes = ["ta-textfield"];
    props.disabled && classes.push("ta-is-disabled");
    props.search && classes.push("ta-textfield--search");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div
      className={
        buildClasses() + (inputValue !== "" ? " ta-textfield--populated" : "")
      }
      style={props.style}
    >
      <div className="ta-textfield_field">
        {props.symbol && (
          <Icon symbol={props.symbol} className="ta-textfield_symbol" />
        )}
        {props.label && (
          <label className={"ta-textfield_label "}>{props.label} </label>
        )}

        <input
          ref={inputRef}
          onChange={(e) => handleChange(e)}
          type="text"
          value={inputValue}
          placeholder={props.placeholder}
          className="ta-textfield_input"
          maxLength={props.maxLength}
          name={props.name}
          onKeyDown={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        {props.reset && (
          <Button
            className={
              "ta-textfield_clear " +
              (inputValue !== "" ? "" : "ta-textfield_clear--hidden")
            }
            icon
            symbol="delete_circle"
            onClick={() => setInputValue("")}
          />
        )}
      </div>
      {props.support && (
        <div className="ta-textfield_support">{props.support}</div>
      )}
    </div>
  );
};

export default TextField;
