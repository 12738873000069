import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Button from "../Trackalyze/Button";

const Ask = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <section className="section">
      <h2 className="typo_display typo_display-subline">{t("ask.title")}</h2>
      <p>{t("ask.contact")}</p>
      <img src="/images/ml_2.jpg" className="hero_ml hero_ml--v2" />
    </section>
  );
};

export default React.memo(Ask);
