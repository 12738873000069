import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Button from "../Trackalyze/Button";

import Typo from "../Trackalyze/Typo";
import Snackbar from "../Trackalyze/Snackbar";
import guiManager from "../utils/guiManager";
import Icon from "../Trackalyze/Icon";

const Accomodation = () => {
  const [copied, setCopied] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <section className="section">
        <h2 className="typo_display typo_display-subline">
          {t("accomodation.title")}
        </h2>

        <p>{t("accomodation.help")}</p>
        <div
          className="ta-shape_m ta-padding_s"
          style={{
            backgroundColor: "rgba(0,0,0,.5)",
            display: "inline-block",
            maxWidth: "640px",
          }}
        >
          <div
            className={
              "bankaccount " +
              (!showForm ? "form_hide" : "form_hide form_hide--animate")
            }
          >
            <p>{t("accomodation.recommendations")}</p>

            <div className=" ta-flex ta-flex--center ta-flex--wrap">
              <div className="ta-margin_xs ta-flex ta-flex--itemscenter ta-flex--column">
                <Button symbol="hotel" text className="ml-button">
                  <a href="https://www.grandhotelboutique.com" target="_blank">
                    Grand Hotel Boutique
                  </a>
                </Button>
                <Typo
                  type="body"
                  size="s"
                  className="ta-flex ta-flex--itemscenter ta-color-text_secondary"
                >
                  <strong>{t("accomodation.code")}</strong>
                  &nbsp; Boda Marisol y Lars
                  <Button
                    icon
                    symbol="content_copy"
                    style={{ zoom: "0.75" }}
                    onClick={() => {
                      navigator.clipboard.writeText("Boda Marisol y Lars");
                      setCopied(true);
                    }}
                  />
                </Typo>
              </div>
              <div className="ta-margin_xs ta-flex ta-flex--itemscenter  ta-flex--column">
                <Button symbol="hotel" text className="ml-button">
                  <a href="https://www.thhotelvivienda.com" target="_blank">
                    TH Hotel Vivienda
                  </a>
                </Button>
                <Typo
                  type="body"
                  size="s"
                  className="ta-flex ta-flex--itemscenter ta-color-text_secondary"
                >
                  <strong>{t("accomodation.code")}</strong>
                  &nbsp; THM&L051024
                  <Button
                    icon
                    style={{ zoom: "0.75" }}
                    symbol="content_copy"
                    onClick={() => {
                      navigator.clipboard.writeText("THM&L051024");
                      setCopied(true);
                    }}
                  />
                </Typo>
              </div>
              <div className="ta-margin_xs ta-flex ta-flex--column">
                <Button symbol="hotel" text className="ml-button">
                  <a
                    href="https://www.hotelmagnotepatitlan.com"
                    target="_blank"
                  >
                    Hotel Magno
                  </a>
                </Button>
              </div>
              <div className="ta-margin_xs ta-flex ta-flex--column">
                <Button symbol="hotel" text className="ml-button">
                  <a href="https://www.grandcasanaranjos.com" target="_blank">
                    Grand Casa Naranjos
                  </a>
                </Button>
              </div>
              <br />
              <br />
              <br />
              <Typo size="s" className="ta-color-text_secondary">
                {t("accomodation.usecode")}
              </Typo>
            </div>

            <br />
            <br />
          </div>

          {!showForm && (
            <Button
              onClick={() => setShowForm(!showForm)}
              outline
              className={"ml-button"}
              symbol={showForm ? "arrow_upward" : "arrow_downward"}
            >
              {t("accomodation.showaccomodations")}
            </Button>
          )}
          {showForm && (
            <Button
              onClick={() => setShowForm(!showForm)}
              outline
              icon
              className={"ml-button"}
              symbol={showForm ? "close" : "close"}
            />
          )}
        </div>
      </section>

      {copied &&
        guiManager(
          <Snackbar
            zIndex={9999}
            animate
            fixed
            timeOut={3000}
            onAfterAnimate={() => setCopied(false)}
          >
            <div className="ta-flex ta-flex--itemscenter">
              <Icon symbol="content_copy" className="ta-margin_right-xxs" />
              {t("accomodation.clipboard")}
            </div>
          </Snackbar>
        )}
    </>
  );
};

export default React.memo(Accomodation);
