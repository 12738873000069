import "babel-polyfill";

import { Route, BrowserRouter, Routes } from "react-router-dom";

import Gui from "./Gui";
import React from "react";
import styles from "../sass/styles.scss";
import i18next from "../js/locale/i18n";

import Party from "./party/Party";
import Teaser from "./teaser/Teaser";
import Test from "./teaser/Test";
import SuccessModal from "./teaser/SuccessModal";
import Confirmation from "./party/Confirmation";

// set language from localstorage
const lngCheck =
  localStorage.getItem("language") !== null
    ? JSON.parse(localStorage.getItem("language"))
    : "";
if (lngCheck !== "") {
  i18next.changeLanguage(lngCheck);
}
console.log("lang", i18next.options.languages);
// end set language from localstorage

export const App = () => {
  return (
    <BrowserRouter>
      <Gui>
        <Routes>
          <Route path="/" element={<Party />}></Route>
          <Route path="/test" element={<Test />}>
            <Route path="success" element={<SuccessModal />} />
          </Route>
          <Route path="/confirmation" element={<Confirmation />}></Route>
          <Route path="/party" element={<Party />}></Route>
        </Routes>
      </Gui>
    </BrowserRouter>
  );
};
