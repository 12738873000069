import React, { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import TextField from "../Trackalyze/TextField";
import Button from "../Trackalyze/Button";
import Icon from "../Trackalyze/Icon";
import RadioButton from "../Trackalyze/RadioButton";

const ConfirmForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    message: "",
    confirm: "",
  });
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({ ...formData, confirm: event.target.value });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    if (formData.name === "TESTONLY") {
      setSuccess(true);
      e.preventDefault();
      return false;
    }

    console.log("send formData", formData);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "confirmation", ...formData }),
    })
      .then(() => setSuccess(true))
      .catch((error) => alert(error));
    e.preventDefault();

    return false;
  };

  useEffect(() => {
    setFormData({ ...formData, name: "", message: "", confirm: "" });
    setShowForm(false);

    console.log("formData Name: ", formData.name);
  }, [success]);

  return (
    <>
      <section
        className="section"
        style={{ zIndex: "9999", position: "relative" }}
      >
        <h2 className="typo_display typo_display-subline">
          {t("confirmation.title")}
        </h2>
        <p>{t("confirmation.giveconfirmation")}</p>
        <div
          className="ta-shape_m ta-padding_s"
          style={{ backgroundColor: "rgba(0,0,0,.5)", display: "inline-block" }}
        >
          <form onSubmit={handleSubmit} autoComplete="off" name="confirmation">
            <div
              className={!true ? "form_hide" : "form_hide form_hide--animate"}
            >
              <fieldset>
                <div className="ta-margin_bottom-s ta-flex ta-flex--itemscenter">
                  <RadioButton
                    name="confirm"
                    value="Yes"
                    checked={formData.confirm === "Yes"}
                    onChange={(value) => handleRadioChange(value)}
                    label={t("confirmation.form_confirm1")}
                  />
                </div>
                <div className="ta-flex ta-flex--itemscenter">
                  <RadioButton
                    name="confirm"
                    value="No"
                    checked={formData.confirm === "No"}
                    onChange={(value) => handleRadioChange(value)}
                    label={t("confirmation.form_confirm2")}
                  />
                </div>
              </fieldset>
              <input type="hidden" name="form-name" value="confirmation" />
              <TextField
                name="name"
                label={t("confirmation.form_name")}
                value={formData.name}
                onChange={(value) => setFormData({ ...formData, name: value })}
                support={t("confirmation.form_name_support")}
              />
              <TextField
                name="message"
                label={t("confirmation.form_message")}
                value={formData.message}
                onChange={(value) =>
                  setFormData({ ...formData, message: value })
                }
                support={t("confirmation.form_message_support")}
              />
              <br />
              <br />
              <Button
                type="submit"
                symbol="thumb_up"
                text
                className="ml-button"
                disabled={formData.confirm === "" || formData.name.length === 0}
              >
                {t("confirmation.form_submit")}
              </Button>
            </div>
            {success && (
              <div
                style={{ textAlign: "center" }}
                className="account ta-margin_top-l ta-is-center"
              >
                <Icon
                  symbol="heart"
                  className="ta-color-text_error ta-margin_right-xxs"
                />
                <p className="ta-flex ta-flex--center ta-flex--items">
                  {t("confirmation.thanks")}
                </p>
              </div>
            )}
          </form>
        </div>
      </section>
      <Outlet />
    </>
  );
};

export default ConfirmForm;
