import React from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Teaser = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const daysToGo = (startDate) => {
    // Parse the start date string
    var parts = startDate.split("-");
    var day = parseInt(parts[0]);
    var month = parseInt(parts[1]) - 1; // Month starts from 0 in JavaScript (0 - 11)
    var year = parseInt(parts[2]);

    // Set the current date
    var currentDate = new Date();

    // Set the start date
    var startDate = new Date(year, month, day);

    // Calculate the difference in milliseconds
    var difference_ms = startDate.getTime() - currentDate.getTime();

    // Convert back to days and return
    return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
  };

  return (
    <>
      <div
        className="bg bg--welcome"
        style={{ opacity: "1", textAlign: "center" }}
      >
        <div className="headline typo_display">
          <h1 className="typo_display-headline">Marisol & Lars</h1>
          <p className="">
            October 5, 2024 &nbsp;&middot;&nbsp;Tepatitlan, Mexico
            &nbsp;&middot;&nbsp;
            <strong>{daysToGo("05-10-2024")}</strong> days to go.
          </p>
          <br />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default React.memo(Teaser);
