import React, { useEffect, useState } from "react";

import Typo from "../js/Trackalyze/Typo";

import { useTranslation } from "react-i18next";
import Button from "./Trackalyze/Button";

const SettingsLanguages = () => {
  const { t, i18n } = useTranslation();

  const [languages, setLanguages] = useState([]);

  // i18n.language

  useEffect(() => {
    setLanguages(i18n.options.languages);
    console.log("i18n.language === lng", i18n.options.languages);
  }, []);

  const switchLanguage = (lng) => {
    console.log("switchLanguage", lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("language", JSON.stringify(lng));
  };

  return languages.map((lng, idx) => {
    return (
      <button
        className={
          "ml-button_flag" +
          (i18n.language === lng.id ? " ml-button_flag--active" : "")
        }
        key={"flag_" + idx}
        onClick={() => switchLanguage(lng.id)}
      >
        {/*    <img
          src={"/icons/flag_" + lng.id + ".png"}
          width="24"
          className="ta-shape_xs"
        /> */}
        <span className="ta-margin_leftright-xxs">{lng.id}</span>
      </button>
    );
  });
};

export default React.memo(SettingsLanguages);
