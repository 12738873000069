import React, { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import TextField from "../Trackalyze/TextField";
import Button from "../Trackalyze/Button";
import Typo from "../Trackalyze/Typo";

const Test = () => {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    console.log("send formData", formData);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => alert("Success: " + formData.name))
      .catch((error) => alert(error));
    e.preventDefault();

    return false;
  };

  return (
    <>
      <div
        className="ta-margin_m ta-padding_m ta-shape_m"
        style={{ backgroundColor: "rgba(0,0,0,.5)" }}
      >
        <Typo type="title">Test 21 ajax</Typo>
        <form name="contact" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contact" />
          <TextField
            name="name"
            label={t("Your Name")}
            value={""}
            onChange={(value) => setFormData({ ...formData, name: value })}
          />
          <TextField
            name="email"
            label={t("Your email")}
            value={""}
            onChange={(value) => setFormData({ ...formData, email: value })}
          />
          <Button type="submit" primary>
            Send
          </Button>
        </form>
      </div>

      <Outlet />
    </>
  );
};

export default Test;
