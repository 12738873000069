import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import DekoDivider from "./DekoDivider";

const PartyContent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <section className="section">
        <div>
          <div className="typo_display typo_display-subline">
            {t("party.title")}
          </div>

          <p>{t("party.start")}</p>

          <p>{t("party.location")}</p>

          <DekoDivider variant="2" />

          <p>{t("party.nokids")}</p>
          <DekoDivider variant="2" />
          <p>{t("party.parking")}</p>
          <p>
            {t("party.transport")} <br />
            <br />
            {t("party.detailsfollow")}{" "}
          </p>
          <DekoDivider variant="2" />
          <p>{t("party.dresscode")} </p>
        </div>
      </section>
    </>
  );
};

export default React.memo(PartyContent);
