import Icon from "./Icon";
import React from "react";

const Button = (props) => {
  const buildClasses = () => {
    const classes = [];
    !props.pure && classes.push("ta-button");
    props.disabled && classes.push("ta-is-disabled");
    props.error && classes.push("ta-button--error");
    props.semi && classes.push("ta-button--semi");
    props.neutral && classes.push("ta-button--neutral ");
    props.primary && classes.push("ta-button--primary");
    props.secondary && classes.push("ta-button--secondary");
    props.tertiary && classes.push("ta-button--tertiary");
    props.tonal && classes.push("ta-button--tonal");
    props.active && classes.push("ta-button--active");
    props.outline && classes.push("ta-button--outline");
    props.text && classes.push("ta-button--text");
    props.icon && classes.push("ta-button--icon");
    props.fab && classes.push("ta-button--fab");
    props.small && classes.push("ta-height_auto");
    props.stacked && classes.push("ta-button--stacked");

    if (props.elevation) {
      typeof props.elevation === "number" || typeof props.elevation === "string"
        ? classes.push("ta-elevation_shadow-" + props.elevation)
        : classes.push("ta-elevation_shadow-1");
    }

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  const action = (e) => {
    props.onClick({
      clientWidth: e.currentTarget.clientWidth,
      clientHeight: e.currentTarget.clientHeight,
      offsetLeft: e.currentTarget.offsetLeft,
      offsetTop: e.currentTarget.offsetTop,
      clientX: e.nativeEvent.clientX,
      clientY: e.nativeEvent.clientY,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    });
  };

  return props.pure ? (
    <button
      className={buildClasses()}
      type={props.type ? props.type : "button"}
      name={props.name}
      onClick={props.onClick && action}
    >
      {props.children}
    </button>
  ) : (
    <button
      data-variant={props.variant}
      onClick={props.onClick && action}
      className={buildClasses()}
      style={props.style}
      type={props.type ? props.type : "button"}
      name={props.name}
    >
      {props.icon ? (
        <Icon
          symbol={props.symbol}
          size={props.small ? "s" : ""}
          {...props.iconProps}
        />
      ) : (
        <>
          {props.symbol && (
            <Icon
              symbol={props.symbol}
              {...props.iconProps}
              className="ta-button_icon"
            />
          )}
          {props.children && (
            <label className="ta-button_label">{props.children}</label>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
