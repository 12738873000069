import React, { useEffect, useState } from "react";
import Button from "./Button";
import Icon from "./Icon";

const RadioButton = ({ label, checked, onChange, value, name }) => {
  const btnId = name + "" + Math.floor(Math.random() * Date.now());

  return (
    <label htmlFor={btnId} className="ta-flex ta-flex--itemsstart">
      <input
        type="radio"
        checked={checked}
        name={name}
        onChange={onChange}
        value={value}
        id={btnId}
        className="ta-not-displayed"
      />
      <Icon
        className={checked ? "ta-color-text_primary" : ""}
        symbol={checked ? "control_radio_checked" : "control_radio_unchecked"}
      />
      <span className="ta-margin_left-xs"></span>
      {label}
    </label>
  );
};

export default RadioButton;
