import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const WelcomeContent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const daysToGo = (startDate) => {
    // Parse the start date string
    var parts = startDate.split("-");
    var day = parseInt(parts[0]);
    var month = parseInt(parts[1]) - 1; // Month starts from 0 in JavaScript (0 - 11)
    var year = parseInt(parts[2]);

    // Set the current date
    var currentDate = new Date();

    // Set the start date
    var startDate = new Date(year, month, day);

    // Calculate the difference in milliseconds
    var difference_ms = startDate.getTime() - currentDate.getTime();

    // Convert back to days and return
    return Math.ceil(difference_ms / (1000 * 60 * 60 * 24));
  };

  return (
    <>
      <section className="section ta-flex--start">
        <div className="ta-is-center">
          <h1 className="ta-margin_top-xxl ta-margin_bottom-m typo_display typo_display-headline">
            Marisol<span className="mplusl">&</span>Lars
          </h1>

          <div className="ta-is-center">
            <strong className="typo_display typo_display-counter">
              {daysToGo("05-10-2024")}
            </strong>{" "}
            {t("welcome.daystogo")}
          </div>
          <p className="ta-margin_top-xxs">
            {t("welcome.date")}
            {/*  <br />
            Tepatitlan&nbsp;&middot;&nbsp;Jalisco&nbsp;&middot;&nbsp;Mexico
          */}
          </p>
          <img src="/images/ml_3.jpg" className="hero_ml" />

          <p className="typo_display">{t("welcome.bewithus")}</p>
        </div>
      </section>
    </>
  );
};

export default React.memo(WelcomeContent);
