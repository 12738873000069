import React, { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import LocationContent from "../location/LocationContent";
import DekoDivider from "./DekoDivider";
import PartyContent from "./PartyContent";
import WelcomeContent from "../welcome/WelcomeContent";
import ConfirmForm from "./ConfirmForm";
import Ask from "./Ask";
import Accomodation from "./Accomodation";
import Gifts from "./Gifts";
import SettingsLanguages from "../SettingsLanguages";

const Confirmation = () => {
  const [init, setInit] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    document.getElementById("bg").classList.add("background--animate");

    window.setTimeout(() => {
      setInit(true);
    }, 500);
  }, []);

  return (
    <>
      <div className="ta-margin_top-s ta-flex ta-flex--center ta-flex--itemscenter">
        <SettingsLanguages />
      </div>
      <div
        className={
          "init_fade sections ta-margin_m ta-is-center" +
          (init ? " init_fade--animation" : "")
        }
      >
        <WelcomeContent />
        <DekoDivider />
        <LocationContent />
        <DekoDivider />
        <PartyContent />
        <DekoDivider />
        <Accomodation />

        <DekoDivider />
        <Gifts />
        <DekoDivider />
        <ConfirmForm />

        <DekoDivider />
        <Ask />
      </div>
      <div style={{ margin: "0 0 128px 0" }} className="ta-is-center"></div>
    </>
  );
};

export default React.memo(Confirmation);
