import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Button from "../Trackalyze/Button";
import Typo from "../Trackalyze/Typo";
import Icon from "../Trackalyze/Icon";
import DekoDivider from "./DekoDivider";
import Snackbar from "../Trackalyze/Snackbar";
import guiManager from "../utils/guiManager";

const Gifts = () => {
  const [copied, setCopied] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <section className="section">
      <h2 className="typo_display typo_display-subline">{t("gifts.title")}</h2>

      <p>{t("gifts.withyou")}</p>
      <p>{t("gifts.givemore")}</p>
      <div
        className="ta-shape_m ta-padding_s"
        style={{
          backgroundColor: "rgba(0,0,0,.5)",
          display: "inline-block",
          minWidth: "340px",
        }}
      >
        {showForm ? (
          <img src="/images/reisekasse-1.jpg" className="gifts_img" />
        ) : (
          <img src="/images/honeymoonfund.jpg" className="gifts_img" />
        )}

        <div
          className={
            "bankaccount " +
            (!showForm ? "form_hide" : "form_hide form_hide--animate")
          }
        >
          <div className="ta-margin_top-s">
            <p className="ta-is-center ta-color-text_secondary">
              {t("gifts.bankaccounts")}
            </p>

            <div className="ta-flex-ta-flex--even ta-is-center">
              <div className="ta-margin_bottom-">
                <p className="account">
                  <font size="4" className="ta-color-text_secondary">
                    Mexico
                  </font>
                  <br />
                  <br />
                  <font size="3">
                    Marisol Georgina Hurtado Barba
                    <br />
                    Account:&nbsp;
                    <span className="ta-color-text_secondary">7660510726</span>
                    <br />
                    CLABE:&nbsp;
                    <span className="ta-color-text_secondary">
                      002164460100441030
                    </span>
                    <br />
                    BANAMEX
                  </font>
                </p>
              </div>
              <div className="ta-margin_bottom-">
                <p className="account">
                  <font size="4" className="ta-color-text_secondary">
                    Europe
                  </font>
                  <br />
                  <br />
                  <font size="3">
                    Marisol Georgina Hurtado Barba
                    <br />
                    Commerzbank
                    <br />
                    BIC:&nbsp;
                    <span className="ta-color-text_secondary">COBADEFFXXX</span>
                    <br />
                    IBAN:&nbsp;
                    <span className="ta-color-text_secondary">
                      DE44 6004 0071 0310 5491 00
                    </span>
                  </font>
                </p>
              </div>

              <DekoDivider variant="2" />

              <div>
                <h3 className="ta-color-text_secondary">PayPal</h3>
                <font size="2">{t("gifts.paypal")}</font>
                <div>
                  <Button text className="ml-button">
                    <a href="https://paypal.me/marisolylars" target="_blank">
                      PayPal Marisol y Lars
                    </a>
                  </Button>
                </div>
              </div>
            </div>

            <p className="ta-margin_top-l ta-flex-ta-flex--center ta-flex--itemscenter  ta-is-center">
              <Typo>{t("gifts.thankyou")}</Typo>
              <Icon
                symbol="heart"
                className="ta-color-text_error ta-margin_top-xs"
              />
            </p>
          </div>
        </div>
        {!showForm && (
          <Button
            onClick={() => setShowForm(!showForm)}
            outline
            className={"ml-button"}
            symbol={showForm ? "arrow_upward" : "arrow_downward"}
          >
            {t("gifts.donate")}
          </Button>
        )}
        {showForm && (
          <Button
            onClick={() => setShowForm(!showForm)}
            outline
            icon
            className={"ml-button"}
            symbol={showForm ? "close" : "close"}
          />
        )}
      </div>
      {copied &&
        guiManager(
          <Snackbar
            zIndex={9999}
            animate
            fixed
            timeOut={3000}
            onAfterAnimate={() => setCopied(false)}
          >
            <div className="ta-flex ta-flex--itemscenter">
              <Icon symbol="content_copy" className="ta-margin_right-xxs" />
              {t("accomodation.clipboard")}
            </div>
          </Snackbar>
        )}
    </section>
  );
};

export default React.memo(Gifts);
