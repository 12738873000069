import { App } from "./js/App";
import React from "react";
import ReactDOM from "react-dom";
import DeviceProvider from "./Context/DeviceProvider";

ReactDOM.render(
  <DeviceProvider>
    <App />
  </DeviceProvider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
