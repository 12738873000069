import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import lng_en from "../../locales/en.json";
import lng_de from "../../locales/de.json";
import lng_es from "../../locales/es.json";

// check for browser language
let lngCheck = "en";

lngCheck = /^en\b/.test(navigator.language) ? "en" : lngCheck;
lngCheck = /^de\b/.test(navigator.language) ? "de" : lngCheck;
lngCheck = /^es\b/.test(navigator.language) ? "es" : lngCheck;

console.log("language", lngCheck);
// END check for browser language

i18n.use(initReactI18next).init({
  languages: [
    { id: "en", label: "English" },
    { id: "de", label: "Deutsch" },
    { id: "es", label: "Español" },
  ],
  resources: {
    en: { translation: lng_en },
    de: { translation: lng_de },
    es: { translation: lng_es },
  },
  lng: lngCheck.toString(),
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18n;
