import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const SuccessModal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <div className="ta-color_primary">It Worked</div>
    </>
  );
};

export default React.memo(SuccessModal);
