import React from "react";

const DekoDivider = (props) => {
  return (
    <>
      {!props.variant && <img src="/images/deko_1.png" className="deko_1" />}
      {props.variant == "2" && (
        <img src="/images/deko_2.png" className="deko_2" />
      )}
    </>
  );
};

export default DekoDivider;
