import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Button from "../Trackalyze/Button";

const LocationContent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <section className="section">
        <div>
          <h2 className="typo_display typo_display-subline">
            {t("venue.title")}
          </h2>

          <p>
            Hacienda Santa Barbara
            <br />
            47714 Tepatitlán de Morelos
            <br />
            Jalisco, Mexico
          </p>

          <Button symbol="map" outline className="ml-button">
            <a target="_blank" href="https://maps.app.goo.gl/F2n94yUrsNgqXjtq6">
              {t("venue.googlemaps")}
            </a>
          </Button>
        </div>
      </section>
    </>
  );
};

export default React.memo(LocationContent);
